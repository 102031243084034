import {
  hideElement,
  reportError,
  showElement,
  triggerAnalyticsEvent,
} from "./utils";

export const initNewsletterRegistrationForm = () => {
  const formEl = <HTMLFormElement | null>(
    document.querySelector("#js-newsletter-form")
  );
  if (!formEl) return;

  const submitEl = formEl.querySelector(".js-newsletter-form-submit");
  const errorEl = formEl.querySelector(".js-newsletter-form-error");
  const successEl = formEl.querySelector(".js-newsletter-form-success");
  const innerEl = formEl.querySelector(".js-newsletter-form-inner");

  formEl.addEventListener("submit", async (event) => {
    event.preventDefault();

    try {
      const email: string | undefined = formEl["email"].value;
      if (!email) throw new Error("invalid email value");

      hideElement(errorEl);
      hideElement(successEl);
      submitEl && submitEl.setAttribute("disabled", "");

      const response = await fetch(formEl.action, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          isHuman: "ofCourse",
        }),
      });

      if (!response.ok) {
        throw new Error("Error while submitting newsletter request");
      }

      hideElement(innerEl);
      showElement(successEl);

      triggerAnalyticsEvent({
        eventAction: "conversion",
        eventCategory: "Newsletter-Anmeldung",
        // TODO: set eventLabel to undefined
        eventLabel: "",
      });
    } catch (error) {
      showElement(errorEl);
      reportError(error as Error);
    } finally {
      submitEl && submitEl.removeAttribute("disabled");
    }
  });
};
