const expand = (trigger: HTMLAnchorElement, box: HTMLElement) => {
  box.classList.remove("collapse", "show");
  box.classList.add("collapsing");
  const sectionHeight = box.scrollHeight;
  box.style.height = sectionHeight + "px";

  const onTransitionEnd = () => {
    box.removeEventListener("transitionend", onTransitionEnd);
    box.style.height = "";
    box.classList.remove("collapsing");
    box.classList.add("collapse", "show");
  };

  box.addEventListener("transitionend", onTransitionEnd);

  trigger.classList.remove("collapsed");
  trigger.setAttribute("aria-expanded", "true");
};

const collapse = (trigger: HTMLAnchorElement, box: HTMLElement) => {
  const sectionHeight = box.scrollHeight;
  box.classList.remove("collapse", "show", "collapsing");

  window.requestAnimationFrame(() => {
    box.style.height = sectionHeight + "px";
    box.classList.add("collapsing");

    window.requestAnimationFrame(() => {
      box.style.height = "";

      const onTransitionEnd = () => {
        box.removeEventListener("transitionend", onTransitionEnd);
        box.classList.remove("collapsing");
        box.classList.add("collapse");
      };

      box.addEventListener("transitionend", onTransitionEnd);
    });
  });

  trigger.classList.add("collapsed");
  trigger.setAttribute("aria-expanded", "false");
};

export const onCollapseToggle = (target: HTMLElement, event: MouseEvent) => {
  const trigger = target.closest<HTMLAnchorElement>(
    'a[data-toggle="collapse"]',
  );

  if (!trigger) return;
  event.preventDefault();

  const box = document.querySelector<HTMLElement>(trigger.hash);

  if (!box) return;

  const isExpanded = box.classList.contains("show");

  isExpanded ? collapse(trigger, box) : expand(trigger, box);
};
