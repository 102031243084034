import {
  AllSearchParamKeys,
  BoardNumber,
  ChildrenAgesType,
  DataAnalyticsEventAttr,
  DataLayerAnalyticsEvent,
  Language,
  SearchParamsParsed,
  SearchParamsRaw,
  SearchSuggestionType,
} from "./@types/types";
import languagesJSON from "./languages.json";

export const currentLanguage = <Language>document.documentElement.lang;

export const themeUrl = "/user/themes/einfachsuedtirol";

export const translations = languagesJSON[currentLanguage];

export const privacyURL = document.body.dataset["privacyUrl"] ?? "";
export const termsURL = document.body.dataset["termsUrl"] ?? "";

export const createRangeArray = (length: number) =>
  Array.apply(null, { length } as any).map<number>(Number.call, Number);

export const getHotelDetailsUrlWithoutParams = (slug: string) =>
  `/hotel/${slug}`;

export const getHotelDetailsUrl = (slug: string, search: SearchParamsRaw) =>
  `${getHotelDetailsUrlWithoutParams(slug)}?${new URLSearchParams(
    convertObjectValuesToStrings(search),
  ).toString()}`;

const intlNumber2Fraction = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const intlNumber0Fraction = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  maximumFractionDigits: 0,
});

export const numberFormat = (value: number, fractionDigits: 0 | 2) =>
  (fractionDigits === 0 ? intlNumber0Fraction : intlNumber2Fraction).format(
    value,
  );

/**
 * Returns a string without any tags from a html string
 * TODO: is this XSS safe, e.g. <script> tags?
 */
export const stripTags = (input: string): string | null => {
  const el = document.createElement("div");
  el.innerHTML = input;
  return el.textContent;
};

/**
 * Get the parameters for a hotel(s) search from the url
 * Values which are not set are returned as `undefined`
 */
export const getSearchParams = (): SearchParamsParsed => {
  const rawParams = getSearchParamsRaw();
  const searchParams: SearchParamsParsed = {
    occupations: rawParams.occupations
      ? <number[][]>JSON.parse(rawParams.occupations)
      : [[18, 18]],
    seed: rawParams.seed ? +rawParams.seed : Date.now(),
    service: <BoardNumber>(rawParams.service ? +rawParams.service : 0),
    accommodationId: rawParams.accommodationId
      ? +rawParams.accommodationId
      : undefined,
    arrival: rawParams.arrival,
    departure: rawParams.departure,
    locationId: rawParams.locationId ? +rawParams.locationId : undefined,
    searchTerm: rawParams.searchTerm,
  };

  return searchParams;
};

const allSearchParams: { [key in AllSearchParamKeys]: true } = {
  accommodationId: true,
  arrival: true,
  departure: true,
  locationId: true,
  occupations: true,
  searchTerm: true,
  seed: true,
  service: true,
};

/**
 * Get the search params related to a hotel search from the URL
 */
export const getSearchParamsRaw = (): SearchParamsRaw => {
  const searchParams = new URLSearchParams(location.search);
  const parsedSearchParams: { [key: string]: string } = {};

  for (const [key, value] of searchParams.entries()) {
    parsedSearchParams[key] = value;
  }

  return keys(allSearchParams).reduce<SearchParamsRaw>(
    (acc, key) => ({ ...acc, [key]: parsedSearchParams[key] }),
    {},
  );
};

export const loadScript = (src: string): Promise<Event> =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    document.head.appendChild(script);
    script.addEventListener("load", resolve);

    const onError = (event: ErrorEvent) => {
      script.removeEventListener("error", onError);
      reject(event);
    };

    script.addEventListener("error", onError);
  });

export const occupationsToString = (
  adults: number,
  childrenAges: ChildrenAgesType,
  childrenState: number,
) => {
  const adultsArray = [...Array(adults)].map(() => 18);

  const childrenArray = keys(childrenAges)
    .map((value: keyof ChildrenAgesType) => childrenAges[value])
    .slice(0, childrenState);

  const array = [...adultsArray, ...childrenArray];

  return `[[${array.toString()}]]`;
};

export const getISODate = (date: Date) => date.toISOString().substring(0, 10);

/**
 * Typed Object.keys
 */
export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

export const widgetUrl = "https://widget.bookingsuedtirol.com/v2/bundle.js";

/**
 * Hide an HTML element (add [hidden] attr)
 */
export const hideElement = (element: Element | null) =>
  element && element.setAttribute("hidden", "");

/**
 * Show a HTML element (remove [hidden] attr)
 */
export const showElement = (element: Element | null) =>
  element && element.removeAttribute("hidden");

const replaceWithNewSearchParams = (searchParamsRaw: SearchParamsRaw) =>
  history.replaceState(
    undefined,
    "",
    `${location.pathname}?${new URLSearchParams(
      convertObjectValuesToStrings(searchParamsRaw),
    ).toString()}`,
  );

/**
 * Updates the search query params in the url according to the output
 * of the transform function parameter using history.replaceState
 */
export const updateSearchQuery = (
  transform: (searchParamsRaw: SearchParamsRaw) => SearchParamsRaw,
) => {
  const searchParamsRaw = getSearchParamsRaw();
  const newSearchParamsRaw = transform(searchParamsRaw);
  replaceWithNewSearchParams(newSearchParamsRaw);
};

export const isDataAnalyticsEventAttr = (
  input: object,
): input is DataAnalyticsEventAttr => {
  const propertiesValid = ["category", "action", "label"].every(
    (key) => typeof (<any>input)[key] === "string",
  );
  const valueValid = ["string", "number", "undefined"].some(
    (typeValue) => typeof (<DataAnalyticsEventAttr>input).value === typeValue,
  );

  return propertiesValid && valueValid;
};

export const triggerAnalyticsEvent = async (data: DataLayerAnalyticsEvent) => {
  if (!window.dataLayer) {
    const message = "Google Tag Manager not set up on site.";
    console.warn(message);
    const Sentry = await import("@sentry/browser");
    Sentry.captureMessage(message);
    return;
  }

  window.dataLayer.push({
    event: "analyticsEvent",
    ...data,
  });
};

export const reportError = async (error: Error) => {
  console.error(error);
  const Sentry = await import("@sentry/browser");
  Sentry.captureException(error);
};

const translatedSearchSuggestionTypes = ["hot", "reg", "com"] as const;

type TranslatedSearchSuggestionType =
  (typeof translatedSearchSuggestionTypes)[number];

export const checkIsTranslatedSearchSuggestionType = (
  searchSuggestionType: SearchSuggestionType,
): searchSuggestionType is TranslatedSearchSuggestionType =>
  translatedSearchSuggestionTypes.includes(
    searchSuggestionType as TranslatedSearchSuggestionType,
  );

export const convertObjectValuesToStrings = (input: {
  [key: string]: number | string | undefined;
}) =>
  keys(input).reduce<{ [key: string]: string }>((acc, key) => {
    const value = input[key];
    return value === undefined
      ? acc
      : {
          ...acc,
          [key]: String(value),
        };
  }, {});

export const getUsercentricsGoogleMapsConsent = (): boolean => {
  const service = window.UC_UI?.getServicesBaseInfo().find(
    ({ name }) => name === "Google Maps",
  );

  return service ? service.consent.status : true;
};

// https://console.cloud.google.com/google/maps-apis/credentials?authuser=1&project=einfachsuedtirol
// Development API key: AIzaSyCq417Cohzk5rixMvF1OAsTHMVnnbIsf1Y
// export const googleMapsAPIKey = "AIzaSyB4-PuRd2hkXkU875_q2j0MjqZYPOzuAn4";
export const googleMapsAPIKey = "AIzaSyA2J5VPO3wxCuKGies2r_OiJMF4qoWgeao";

export const trustyouKey = "0806c986-73a8-40ff-a374-f8218f57d6d2";
